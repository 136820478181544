.addonsdiv .img-fluid{
  padding: 20% !important;
}

.addonsdiv .card{
  margin-bottom: 0 !important;
}

.bookNowTable .table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem !important;
}

.boldHeader{
  font-size: 22px !important;
  font-weight: bold !important;
}