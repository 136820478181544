.wizard2 > .courseCustomSteps > ul > li{
  cursor: pointer;
}

.wizard > .steps .number{
  padding: 1px !important;
  width: 30px !important;
}
.requiredSign{
  color: red;
  font-weight: bolder;
}
.customBackground{
  padding: 15px 10px;
  background: rgb(98, 108, 220 ,0.1);
  border-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .wizard2 > .courseCustomSteps > ul > li{
    width: 20% !important;
  }
}